<!--
 * @Descripttion: login page
 * @version: 1.1pc
 * @Author: sueRimn
 * @Date: 2019-10-29 10:21:03
 * @LastEditors: cyy 1346967714@qq.com
 * @LastEditTime: 2023-07-25 23:40:07
 -->
<template>
  <div :class="{ AllPageBox: isphone }">
    <myHeader
      v-if="!$store.state.isWindowsAppV2 && !isphone"
      :location="true"
    ></myHeader>
    <div
      v-if="isphone"
      style="
        background: #fff;
        padding-top: 20px;
        width: 375px;
        margin: 0 auto;
        margin-top: 20px;
      "
    >
      <img
        :class="{ headerImgMobile: isphone }"
        src="../assets/img/1.3.9.7/img_logo备份 2@2x.png"
        style="margin: 0 auto"
        alt=""
      />
    </div>
    <main
      class="main"
      :class="{ small: isphone, windowAppV2: $store.state.isWindowsAppV2 }"
    >
      <div class="vt left" v-if="!isphone">
        <div class="t1">微信教育直播变现工具</div>
        <div class="t2">全场景互动直播教学，低成本解决教学营销！</div>
        <img
          @click="open(dataactivity.url)"
          class="t3"
          :src="dataactivity.src"
        />
        <!-- <img
          @click="open(dataactivity.url)"
          v-else
          class="t3"
          src="https://img.dingdingkaike.com/teacher.dingdingkaike/img_dlct.png"
        /> -->
      </div>
      <!-- 注册区域 -->
      <template v-if="hadRegister2">
        <div
          class="vt right"
          style="margin-top: 42px"
          :class="{ small2: isphone, boxMobile: isphone }"
        >
          <div class="menu" style="padding-left: 40px" v-if="!isphone">
            <div
              class="t1"
              style="color: #1b9d97; font-weight: bold; margin-bottom: 30px"
            >
              免费注册网校
            </div>
          </div>
          <el-form
            :class="{ formInPhone: isphone }"
            ref="form"
            :model="form"
            :label-position="isphone ? 'top' : 'right'"
            label-width="80px"
            @submit.native.prevent
          >
            <el-form-item label="手机号">
              <el-input
                type="tel"
                :class="{ small_zc: isphone }"
                style="width: 290px"
                v-model="form.phone"
                placeholder="请输入手机号"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码">
              <div class="account-input verify-code" style="margin-bottom: 0">
                <!-- style="height:40px;" -->
                <el-input
                  type="tel"
                  v-model.trim="form.verify_code"
                  maxlength="4"
                  placeholder="请输入验证码"
                />
                <get-verify-code
                  :isbind="1"
                  :befervertify="1"
                  :type="1"
                  :value="form.phone"
                  timesName="zcmcode"
                  :otherWay="false"
                  @getPhoneError="getPhoneError2"
                  @callback="nextTime4 = arguments[0]"
                >
                  <button
                    style="height: 40px"
                    class="code register-code2"
                    :class="{ mobleCode: isphone, zcCodeBtn: isphone }"
                  >
                    {{ nextTime4 > 0 ? `${nextTime4}s后重试` : '获取验证码' }}
                  </button>
                </get-verify-code>
              </div>
            </el-form-item>

            <el-form-item label="密码">
              <el-input
                show-password
                v-model="first_password"
                maxlength="16"
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                :class="{ small_zc: isphone }"
                style="width: 290px"
                placeholder="设置登录密码，支持6-16位字母或数字"
              ></el-input>
            </el-form-item>

            <el-form-item label="确认密码">
              <el-input
                maxlength="16"
                show-password
                :class="{ small_zc: isphone }"
                style="width: 290px"
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                v-model="form.password"
                placeholder="再次输入密码，支持6~16位字母或数字"
              ></el-input>
            </el-form-item>
          </el-form>
          <!-- <div class="t2 default-t2 register-code">
              <img v-show="qrcode" :src="qrcode" />
            </div>
            <div class="t3">使用微信扫描二维码注册网校</div> -->
          <button
            :class="{ small_zc_bt: isphone }"
            class="account-login-btn"
            @click.stop="submit"
            style="width: 290px; margin-left: 82px; height: 40px"
            native-type="submit"
          >
            立即注册
          </button>
          <button
            :class="{ small_zc_bt: isphone }"
            class="account-login-btn-register"
            @click.stop="changeLoginType(1)"
            style="width: 290px; margin: 14px 0 14px 82px"
          >
            已有账号，立即登录
          </button>

          <div :class="{ small_zc_bt: isphone }" class="agreement-txt">
            <label
              style="
                float: left;
                display: flex;
                align-items: center;
                cursor: pointer;
                user-select: none;
              "
            >
              <el-checkbox
                style="float: left; margin-right: 5px"
                v-model="isAgreement"
              ></el-checkbox>
              我已阅读并同意
            </label>
            <div class="policy" @click.stop="toProtocol(1)">
              《叮叮开课使用协议》
            </div>
            <div class="policy" @click.stop="toProtocol(2)">《隐私权政策》</div>
          </div>
        </div>
      </template>
      <!--登录区域-->
      <template v-else-if="isLoginArea">
        <div
          class="vt right"
          :class="{ small2: isphone }"
          v-if="!isDefaultShowWxlogin && !customJgInfo.isCustome"
        >
          <template v-if="hadRegister">
            <div class="menu">
              <div class="t1">微信登录网校（管理员和老师）后台</div>
            </div>
            <div class="t2 default-t2">
              <img v-show="qrcode" :src="qrcode" />
            </div>
            <div class="t3">使用微信扫描二维码登录网校管理后台</div>
            <!-- <div class="t3">开通网校也请扫上面二维码</div> -->
            <button
              class="account-login-btn register-btn"
              @click.stop="toggleArea('scan')"
              style="margin: 20px auto 0"
            >
              免费注册网校
            </button>
          </template>
        </div>

        <div
          class="vt right"
          :class="{ small2: isphone, boxMobile: isphone }"
          v-else
        >
          <div
            v-if="!customJgInfo.isCustome"
            class="menu"
            style="padding-left: 40px"
          >
            <div
              v-for="item in loginType"
              :key="item.id"
              class="t1"
              :class="{
                selected: item.id === selectedLoginType,
                loginMobile: isphone,
                loginSelected: isphone,
              }"
              @click="switchLoginType(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-else class="menu">
            <div class="t1">登录网校（管理员和老师）后台</div>
          </div>
          <!--密码/验证码登录-->
          <div v-if="selectedLoginType === 1" key="1">
            <el-form
              :class="{ formInPhone: isphone }"
              :label-position="isphone ? 'top' : 'right'"
              ref="form"
              :model="form"
              label-width="80px"
              style="margin-top: 40px"
              @submit.native.prevent
            >
              <el-form-item label="手机号">
                <div
                  class="account-input mt newpwdClass"
                  :class="{
                    'warnning-input': accountInfo.phoneWarnning && warningIs_1,
                    mobileplaceholder: isphone,
                    newpwdClassPhone: isphone,
                  }"
                >
                  <el-input
                    type="tel"
                    :class="{ mobileInput: isphone }"
                    maxlength="11"
                    @focus="isFocus(1)"
                    onkeyup="value=value.replace(/\D/g,'')"
                    v-model.trim="accountInfo.phone"
                    @blur="mobileExist($event, 'login', 1)"
                    placeholder="请输入手机号"
                  ></el-input>
                  <i v-if="!accountInfo.phoneFlag">请输入正确手机号</i>
                  <i v-else-if="accountInfo.phone">该手机未注册</i>
                </div>
              </el-form-item>
              <el-form-item label="密码">
                <div
                  class="account-input newpwdClass"
                  :class="{
                    'warnning-input': accountInfo.passwordWarnning,
                    mobileplaceholder: isphone,
                    newpwdClassPhone: isphone,
                  }"
                >
                  <el-input
                    :class="{ mobileInput: isphone }"
                    onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                    v-model.trim="accountInfo.password"
                    type="password"
                    maxlength="16"
                    placeholder="请输入密码"
                  ></el-input>
                  <i>密码有误</i>
                </div>
              </el-form-item>
              <!-- <el-form-item label="验证码">
                <div class="account-input verify-code" :class="{
                    'warnning-input': accountInfo.codeWarnning,
                    mobileplaceholder: isphone
                  }">
                  <el-input :class="{ mobileInput: isphone }" v-model.trim="accountInfo.code" placeholder="请输入验证码"
                    maxlength="4" @keyup.enter="accountLogin"></el-input>
                  <div class="code" :class="{ mobleCode: isphone }">
                    <img @click="getVerifyCode" :src="verifyCode" />
                  </div>
                  <i>验证码有误</i>
                </div>
              </el-form-item> -->
            </el-form>

            <button
              :class="{ small_zc_bt: isphone }"
              class="account-login-btn"
              native-type="submit"
              @click.stop="accountLogin"
            >
              立即登录
            </button>
            <button
              :class="{ small_zc_bt: isphone }"
              v-if="!customJgInfo.isCustome"
              class="account-login-btn register-btn"
              @click.stop="toggleArea('scan')"
            >
              免费注册网校
            </button>
            <div class="remember-pas-box" :class="{ small_zc_bt2: isphone }">
              <div class="left-span-contain">
                <label v-if="!isphone" class="left-span">
                  <el-checkbox
                    style="margin-right: 5px"
                    v-model="isRememberPassword"
                  ></el-checkbox>
                  记住密码
                </label>
                <label v-if="!isphone" class="left-span">
                  <el-checkbox
                    style="margin-right: 5px"
                    v-model="is_persistence"
                  ></el-checkbox>
                  30天免登录
                </label>
              </div>
              <div
                v-if="!customJgInfo.isCustome"
                @click.stop="toggleArea('reset')"
              >
                忘记密码
              </div>
            </div>
          </div>
          <!--验证码登录-->
          <div v-if="selectedLoginType === 2" key="2">
            <el-form
              :class="{ formInPhone: isphone }"
              :label-position="isphone ? 'top' : 'right'"
              ref="form"
              style="margin-top: 40px"
              :model="form"
              label-width="80px"
              @submit.native.prevent
            >
              <el-form-item label="手机号">
                <div
                  class="account-input mt"
                  :class="{
                    'warnning-input': accountInfo.phoneWarnning && warningIs_2,
                    mobileplaceholder: isphone,
                  }"
                >
                  <el-input
                    type="tel"
                    :class="{
                      mobileInput: isphone,
                      mobileplaceholder: isphone,
                    }"
                    maxlength="11"
                    onkeyup="value=value.replace(/\D/g,'')"
                    @focus="isFocus(2)"
                    v-model.trim="accountInfo.phone"
                    @blur="mobileExist($event, 'login', 2)"
                    placeholder="请输入手机号"
                  ></el-input>
                  <i v-if="!accountInfo.phoneFlag">请输入正确手机号</i>
                  <i v-else-if="accountInfo.phone">该手机未注册</i>
                </div>
              </el-form-item>
              <el-form-item label="验证码">
                <div
                  class="account-input verify-code"
                  :class="{
                    'warnning-input': accountInfo.numCodeWarnning,
                    mobileplaceholder: isphone,
                  }"
                >
                  <el-input
                    type="tel"
                    :class="{ mobileInput: isphone }"
                    v-model.trim="accountInfo.numCode"
                    maxlength="4"
                    placeholder="请输入验证码"
                  ></el-input>

                  <get-verify-code
                    :type="6"
                    :value="accountInfo.phone"
                    timesName="yzmcode"
                    :otherWay="false"
                    @getPhoneError="getPhoneError"
                    @callback="nextTime2 = arguments[0]"
                  >
                    <button
                      class="code register-code"
                      :class="{ mobleCode: isphone }"
                      :disabled="accountInfo.phoneWarnning"
                    >
                      {{ nextTime2 > 0 ? `${nextTime2}s后重试` : '获取验证码' }}
                      <div
                        v-if="showVerifyCodeComponent_2"
                        class="divdiv"
                        :class="{ divdivMobile: isphone }"
                        @click.stop="clickYzm(2)"
                      ></div>
                    </button>
                  </get-verify-code>
                  <i>验证码有误</i>
                </div>
              </el-form-item>
            </el-form>
            <button
              :class="{ small_zc_bt: isphone }"
              class="account-login-btn"
              native-type="submit"
              @click.stop="verifyLogin"
            >
              立即登录
            </button>
            <button
              :class="{ small_zc_bt: isphone }"
              v-if="!customJgInfo.isCustome"
              class="account-login-btn register-btn"
              @click.stop="toggleArea('scan')"
            >
              免费注册网校
            </button>
          </div>
        </div>
      </template>
      <!--注册/重置区域-->
      <template v-else>
        <!--重置密码-->
        <div
          class="vt right"
          :class="{ small2: isphone, boxMobile: isphone }"
          v-if="!isNotReset"
        >
          <div class="menu" style="padding-left: 40px">
            <div class="t1" :class="{ loginMobile: isphone }">重置密码</div>
          </div>
          <el-form
            :class="{ formInPhone: isphone }"
            :label-position="isphone ? 'top' : 'right'"
            ref="form"
            :model="form"
            style="margin-top: 20px"
            label-width="80px"
            @submit.native.prevent
          >
            <el-form-item label="手机号">
              <div
                class="account-input mt"
                :class="{
                  'warnning-input': accountInfo.phoneWarnning && warningIs_3,
                }"
              >
                <el-input
                  type="tel"
                  maxlength="11"
                  onkeyup="value=value.replace(/\D/g,'')"
                  v-model.trim="accountInfo.phone"
                  @blur="mobileExist($event, 'login', 3)"
                  @focus="isFocus(3)"
                  placeholder="请输入手机号"
                ></el-input>
                <i v-if="!accountInfo.phoneFlag">请输入正确手机号</i>
                <i v-else-if="accountInfo.phone">该手机号未注册，请先注册</i>
              </div>
            </el-form-item>
            <el-form-item label="验证码">
              <div
                class="account-input verify-code"
                :class="{ 'warnning-input': accountInfo.numCodeWarnning }"
              >
                <el-input
                  type="tel"
                  v-model.trim="accountInfo.numCode"
                  maxlength="4"
                  placeholder="请输入验证码"
                ></el-input>
                <get-verify-code
                  :type="7"
                  :value="accountInfo.phone"
                  timesName="resetcode"
                  :otherWay="false"
                  @getPhoneError="getPhoneError"
                  @callback="nextTime3 = arguments[0]"
                >
                  <button
                    class="code register-code"
                    :class="{ rePassword: isphone }"
                    :disabled="accountInfo.phoneWarnning"
                  >
                    {{ nextTime3 > 0 ? `${nextTime3}s后重试` : '获取验证码' }}
                    <div
                      v-if="showVerifyCodeComponent_3"
                      class="divdiv"
                      :class="{ divdivMobile: isphone }"
                      @click.stop="clickYzm(3)"
                    ></div>
                  </button>
                </get-verify-code>
                <i>验证码有误</i>
              </div>
            </el-form-item>
            <el-form-item label="新密码">
              <div
                class="account-input"
                :class="{ 'warnning-input': accountInfo.passwordWarnning }"
              >
                <el-input
                  onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                  v-model="first_password"
                  maxlength="16"
                  type="password"
                  v-model.trim="accountInfo.password"
                  placeholder="输入新密码"
                ></el-input>
                <i>请输入长度为6~16个字符的密码，标点符号无效</i>
              </div>
            </el-form-item>
          </el-form>
          <button
            :class="{ small_zc_bt: isphone }"
            class="account-login-btn"
            @click.stop="passForgot"
            native-type="submit"
          >
            确定
          </button>
          <p
            class="go-other"
            style="width: 100%; text-align: center; margin-left: 40px"
            :class="{ small_zc_bt: isphone, jtGoOther: isphone }"
            @click.stop="toggleArea"
          >
            已有账号，登录网校后台
            <i></i>
          </p>
        </div>
      </template>
      <!--密码切换标识-->
      <div
        v-if="
          !hadRegister2 && isLoginArea && !customJgInfo.isCustome && !isphone
        "
        class="change-login-type"
        :class="{ 'password-login-type': isDefaultShowWxlogin }"
        @click.stop="changeLoginType"
      ></div>
      <div class="toast-txt" v-if="isShowToast">
        {{ newUser.isNew ? '绑定手机号成功！' : '注册手机号成功！' }}
      </div>
    </main>
    <FadeAnimation v-if="isShowFade">
      <div class="tishi-box">
        <h3>温馨提示</h3>
        <p>您已被管理员移出网校，如有疑问请联系管理员。</p>
        <div>
          <span @click.stop="isShowFade = false">创建网校</span>
          <button class="account-login-btn" @click.stop="isShowFade = false">
            确定
          </button>
        </div>
      </div>
    </FadeAnimation>
    <footer class="footer" v-if="!isphone && !$store.state.isWindowsAppV2">
      Copyright © {{ new Date().getFullYear() }}深圳品阔信息技术有限公司 
      <a href="https://beian.miit.gov.cn/" target="_blank">
        粤ICP备15114995号-3
      </a>

      <!-- {{ customJgInfo.footerText || (customJgInfo.isCustome ? '' :
      `Copyright © ${new Date().getFullYear()} 深圳品阔信息技术有限公司
      粤网文[2018]0071-065号
      <a href="https://beian.miit.gov.cn/" target="_blank">
        粤ICP备15114995号-3
      </a>
      `) }} -->
    </footer>

    <el-dialog
      title="温馨提示"
      :close-on-click-modal="false"
      @closed="dialog_close"
      :visible.sync="hadRegister3"
      width="422px"
    >
      <span>该微信未绑定账号，登录失败！请使用密码登录或注册账号！</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialog_sign">密码登录</el-button>
        <el-button type="primary" size="medium" @click.stop="dialog_rigester">
          去注册
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import getEnv from '@/config/getEnv'
import myHeader from '@/components/Header'
import FadeAnimation from '@/components/FadeAnimation'
import getVerifyCode from '@/components/GetVerifyCode'
import { setCookie, getCookie } from '@ass/js/cookie'

export default {
  name: 'login',

  components: {
    FadeAnimation,
    myHeader,
    getVerifyCode,
  },

  data() {
    return {
      warningIs_1: false,
      warningIs_2: false,
      warningIs_3: false,

      isphone: false,

      dataactivity: [], //广告

      //注册时第一次输入的密码
      first_password: '',

      //注册账号
      hadRegister3: false,

      form: {
        phone: '',
        verify_code: '',
        password: '',
      },

      showVerifyCodeComponent_2: true,

      showVerifyCodeComponent_3: true,

      qrcode: '',

      timer: null,

      requestCode: '',

      downloadUrl: '',

      // 选择的登录类型
      loginType: [
        {
          name: '密码登录',
          id: 1,
        },
        {
          name: '验证码登录',
          id: 2,
        },
      ],
      selectedLoginType: 1,

      // 用户账号信息
      verifyCode: '/wxCustom/VerificationCode',

      accountInfo: {
        code: '',
        phone: '',
        title: '',
        password: '',
        newPassword: '',
        numCode: '',
        phoneWarnning: false,
        passwordWarnning: false,
        numCodeWarnning: false,
        titleWarnning: false,
        codeWarnning: false,
        newPasswordWarnning: false,
        isLoading: false,
        titleFlag: false,
        phoneFlag: false,
      },

      // 是否同意协议 true:勾选(同意) false:不勾选(不同意)
      isAgreement: false,

      // 是否记住用户密码 true:记住 false:不记住
      isRememberPassword: false,
      // 30天免登录
      is_persistence: true,

      // 是否是注册区域 true:登录区域 false:注册区域
      isLoginArea: true,

      // 默认显示 微信登录 true: 密码登录   false:  微信二维码登录
      isDefaultShowWxlogin: true,

      // 是否绑定手机 true:已绑定 false:未绑定
      hadRegister: true,

      // 在线网校注册
      hadRegister2: false,

      // 时候是 重置密码 isNotReset:true 不是 false: 是
      isNotReset: true,

      newUser: {},

      // 注册/绑定 倒计时
      nextTime: 0,

      // 验证码登录倒计时
      nextTime2: 0,

      // 忘记密码倒计时
      nextTime3: 0,
      // 注册倒计时
      nextTime4: 0,

      //显示被移除的提示页面
      isShowFade: false,

      tempnewUser: {},

      isShowToast: false,

      //判断是pc观看还是手机观看
      isphoneorpc: false,
    }
  },

  watch: {
    'accountInfo.title'() {
      this.accountInfo.titleFlag = false
      this.accountInfo.titleWarnning = false
    },
    'accountInfo.phone'(value) {
      // console.log(value.length)
      this.accountInfo.phoneFlag = false
      if (value) {
        this.accountInfo.phoneWarnning = this.checkPhoneOrPassword(value, 1)
      }
      if (value.length < 11) {
        this.showVerifyCodeComponent_2 = true
        this.showVerifyCodeComponent_3 = true
      } else {
        let _this = this
        _this.$http({
          name: 'mobileExist',
          url: '/WxCustom/mobileExist',
          data: {
            mobile: _this.accountInfo.phone,
          },
          callback({ exist }) {
            if (exist) {
              _this.showVerifyCodeComponent_2 = false

              _this.showVerifyCodeComponent_3 = false
            }
          },
        })
      }
    },
    'accountInfo.password'(value) {
      if (value) {
        this.accountInfo.passwordWarnning = this.checkPhoneOrPassword(value, 2)
      }
    },
    'accountInfo.numCode'(value) {
      if (value) {
        this.accountInfo.numCodeWarnning = this.checkPhoneOrPassword(value, 3)
      }
    },
    // 'accountInfo.code'(value) {
    //   if (value) {
    //     this.accountInfo.codeWarnning = this.checkPhoneOrPassword(value, 4)
    //   }
    // }
  },

  computed: {
    isWindowsApp() {
      // 是不是windows客户端
      return this.$store.state.isWindowsApp
    },

    customJgInfo() {
      return this.$store.state.customJgInfo
    },
  },

  beforeCreate() {
    this.$store.dispatch('setLocalSessionAndUserKey')

    if (!document.querySelector('#TCaptcha')) {
      // 插入验证码js
      const script = document.createElement('script')
      script.id = 'TCaptcha'
      script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js'
      document.body.appendChild(script)
      script.onerror = () => {
        document.body.removeChild(document.querySelector('#TCaptcha'))
      }
    }
  },

  created() {
    if (this.$route.query.phone) {
      this.form.phone = this.$route.query.phone
    }
    if (!this.hadRegister2) {
      this.initWhenHasCookie()
    }
    window.addEventListener('keydown', this.handkeyCode, true)
    if (this._isPhone&&window.screen.width<=600) {
      this.isphone = true
      // document.body.style.width = '375px'
      document.body.style.minWidth = '375px'
      document.body.style.minHeight = '375px'
      // document.documentElement.style.width = '375px'
      document.documentElement.style.minWidth = '375px'
      document.documentElement.style.minHeight = '375px'
      document.getElementById('app').style.margin = '0'
      document.getElementById('app').style.padding = '0'
    }
    //判断进入路径上要求进入的页面
    // if (this.$route.query.t == 'account') {
    //   this.isDefaultShowWxlogin = true
    // }
    if (this.$route.query.t == 'wx') {
      this.isDefaultShowWxlogin = false
      this.getCode()
    }
    if (this.$route.query.t == 'register') {
      this.hadRegister2 = true
    }
    // if (!this.$route.query.t) {
    // this.updateUrlHash('account')
    // }

    // 添加统计代码
    if (process.env.NODE_ENV === 'production') {
      this.addTongjiScript()
    }
    // else {
    //   this.$router.replace('/login?t=wx')
    // }
    // if (this.hadRegister2) {
    //   this.$router.replace('/login?t=register')
    // }

    this.activity()
    if (/ris/.test(location.hash)) {
      this.toggleArea('scan')
    }

    // if (this.isDefaultShowWxlogin) {
    //   this.getCode()
    // }

    try {
      // 获取来源页面 发送至后台 from 1 2
      var format = new FormData()
      var res = this.$route.query

      for (var i in res) {
        format.append(i, res[i])
      }

      navigator.sendBeacon('/wxCustom/from', format)
    } catch (e) {
      console.error(e)
    }
  },
  mounted() {
    if (this.isphone) {
      document.body.style.background = '#fff'
      var view_num = window.screen.availWidth / 375
      // // var view_num = 0.5
      // // console.log(window.screen.availWidth)
      // // document.getElementById("viewport")
      document
        .getElementById('viewport')
        .setAttribute(
          'content',
          'user-scalable=no, width=375, minimum-scale=' +
            view_num +
            ', initial-scale=' +
            view_num +
            ', maximum-scale=' +
            view_num +
            ''
        )
    } else {
      document.body.style.background = '#f5f5f5'
    }
  },

  destroyed() {
    if (!this.isphone) {
      document.body.style.background = '#f5f5f5'
    } else {
      document.body.style.background = '#fff'
    }
    if (this.isphone && this.$store.state.userInfo.newschool != 1&&window.screen.width>600) {
      document.body.style.minWidth = '1170px'
      document.body.style.minHeight = '660px'
      // document.documentElement.style.width = '375px'
      document.documentElement.style.minWidth = '1170px'
      document.documentElement.style.minHeight = '660px'
      document.getElementById('app').style.marginLeft = '188px'
      document.getElementById('app').style.paddingTop = '60px'
      if (document.getElementById('viewport')) {
        // var _view_num = 1170 / 1170
        // document
        //   .getElementById('viewport')
        //   .setAttribute(
        //     'content',
        //     'user-scalable=yes, width=1170'
        //   )
        // var _view_num = window.screen.availWidth / 1170
        document
          .getElementById('viewport')
          .setAttribute('content', 'user-scalable=yes, width=1170')
      }
    }
    // 停止微信扫码定时器
    clearInterval(this.timer)
  },

  methods: {
    handkeyCode(e) {
      let key = null
      if (window.event === undefined) {
        key = e.keyCode
      } else {
        key = window.event.keyCode
      }

      if (key === 13 && this.$route.path === '/login') {
        if (
          this.selectedLoginType == 1 &&
          !this.hadRegister2 &&
          this.isNotReset
        ) {
          this.accountLogin() //密码登录事件
        }
        if (
          this.selectedLoginType == 2 &&
          !this.hadRegister2 &&
          this.isNotReset
        ) {
          this.verifyLogin() //验证码登录事件
        }
        if (this.hadRegister2) {
          this.submit() //注册事件
        }
        if (!this.isNotReset) {
          this.passForgot()
        }
      }
    },
    clickYzm(id) {
      if (!this.accountInfo.phone) {
        this.accountInfo.phoneWarnning = true
      }
      if (id == 2) {
        if (!this.accountInfo.phone) {
          this.warningIs_2 = true
        }
      }
      if (id == 3) {
        if (!this.accountInfo.phone) {
          this.warningIs_3 = true
        }
      }
    },
    isFocus(id) {
      // if(this.accountInfo.phone){
      this.warningIs_1 = false
      this.warningIs_2 = false
      this.warningIs_3 = false
      if (id == 1) {
        this.warningIs_1 = true
      }
      if (id == 2) {
        this.warningIs_2 = true
      }
      if (id == 3) {
        this.warningIs_3 = true
      }

      // }
    },
    // 更换地址栏hash
    updateUrlHash(query) {
      this.$router.replace({
        path: '/login',
        query: Object.assign({}, this.$route.query, {
          t: query,
        }),
      })
    },

    // 添加统计代码
    addTongjiScript() {
      const script = document.createElement('script')
      // const host = /-test/i.test(location.host)
      //   ? 'test-home.dingdingkaike.com'
      //   : /-release/i.test(location.host)
      //   ? 'release-home.dingdingkaike.com'
      //   : 'www.dingdingkaike.cn'
      script.src = `/Public/tongji.015268753291366.js`
      document.body.appendChild(script)
    },

    /**
     * @name: initWhenHasCookie
     * @test: test font
     * @msg: 当存在cookie时给手机号与密码赋值
     * @param {none}
     * @return: none
     */

    // form: {
    //   phone: '',
    //   verify_code: '',
    //   password: ''
    // },
    dialog_close() {
      this.getCode()
    },
    activity() {
      this.$http({
        url: '/PcLogin/activity',
        data: {},
        callback: ({ code, data }) => {
          if (code == 200) {
            this.dataactivity = data
          }
        },
      })
    },
    submit() {
      if (this.isphone) {
        if (
          !this.form.phone ||
          !this.form.verify_code ||
          !this.form.password ||
          !this.first_password
        ) {
          this.$root.prompt({
            msg: '请先输入内容！',
            customClass: 'mobileMessage_2020_12_9_1_3_9_7',
          })
          return
        }
        if (!this.isAgreement) {
          this.$root.prompt({
            msg: '请先同意并勾选叮叮开课使用协议！',
            customClass: 'mobileMessage_2020_12_9_1_3_9_7',
          })
          return
        }
        if (!/^1[2-9]\d{9}$/.test(this.form.phone)) {
          this.$root.prompt({
            msg: '手机号格式有误，请检查！',
            customClass: 'mobileMessage_2020_12_9_1_3_9_7',
          })
          return
        }
        if (
          !/^[a-zA-Z0-9]{6,16}$/.test(this.form.password) ||
          !/^[a-zA-Z0-9]{6,16}$/.test(this.first_password)
        ) {
          this.$root.prompt({
            msg: '密码格式有误，仅支持6~16位字母或数字！',
            customClass: 'mobileMessage_2020_12_9_1_3_9_7',
          })
          return
        }
        if (this.first_password !== this.form.password) {
          this.$root.prompt({
            msg: '两次输入密码不一致，请检查！',
            customClass: 'mobileMessage_2020_12_9_1_3_9_7',
          })
          return
        }
      } else {
        if (
          !this.form.phone ||
          !this.form.verify_code ||
          !this.form.password ||
          !this.first_password
        ) {
          this.$root.prompt({
            msg: '请先输入内容！',
            dangerouslyUseHTMLString: true,
          })
          return
        }
        if (!this.isAgreement) {
          this.$root.prompt({
            msg: '请先同意并勾选叮叮开课使用协议！',
          })
          return
        }
        if (!/^1[2-9]\d{9}$/.test(this.form.phone)) {
          this.$root.prompt({
            msg: '手机号格式有误，请检查！',
          })
          return
        }
        if (
          !/^[a-zA-Z0-9]{6,16}$/.test(this.form.password) ||
          !/^[a-zA-Z0-9]{6,16}$/.test(this.first_password)
        ) {
          this.$root.prompt({
            msg: '密码格式有误，仅支持6~16位字母或数字！',
          })
          return
        }
        if (this.first_password !== this.form.password) {
          this.$root.prompt({
            msg: '两次输入密码不一致，请检查！',
          })
          return
        }
      }
      this.$http({
        url: '/PcLogin/register',
        data: Object.assign({}, this.form, {
          vr_id: window.dk_queryId,
          source: this.$route.query.source,
        }),
        callback: () => {
          this.$router.push({
            path: '/newschool',
          })
        },
        error: ({ info }) => {
          if (this.isphone) {
            this.$root.prompt({
              msg: info,
              customClass: 'mobileMessage_2020_12_9_1_3_9_7',
            })
          } else {
            this.$root.prompt({
              msg: info,
            })
          }
        },
      })
    },
    dialog_rigester() {
      this.hadRegister3 = false
      this.toggleArea('scan')
    },
    dialog_sign() {
      this.hadRegister3 = false
      this.changeLoginType(1)
    },
    initWhenHasCookie() {
      if (getCookie('lusername') && getCookie('lpassword')) {
        this.accountInfo.phone = getCookie('lusername')
        this.accountInfo.password = getCookie('lpassword')
        this.isRememberPassword = true
      }
      if (getCookie('is_persistence')) {
        this.is_persistence = true
      }
    },

    // 我是老师 我是学生
    open(url) {
      this.$store.dispatch('open', url)
    },

    /**
     * @name: getPhoneError
     * @test: test font
     * @msg: 点击发送验证码，当手机号有错时触发
     * @param {phone:手机号}
     * @return: none
     */
    getPhoneError2() {
      if (this.isphone) {
        if (!this.form.phone) {
          this.$root.prompt({
            msg: '请输入手机号',
            customClass: 'mobileMessage_2020_12_9_1_3_9_7',
          })
          return
        }
        if (!/^1[2-9]\d{9}$/.test(this.form.phone)) {
          this.$root.prompt({
            msg: '手机号格式有误，请检查！',
            customClass: 'mobileMessage_2020_12_9_1_3_9_7',
          })
          return
        }
      } else {
        if (!this.form.phone) {
          this.$root.prompt({
            msg: '请输入手机号',
          })
          return
        }
        if (!/^1[2-9]\d{9}$/.test(this.form.phone)) {
          this.$root.prompt({
            msg: '手机号格式有误，请检查！',
          })
          return
        }
      }

      this.$http({
        name: 'check',
        url: '/PcLogin/check',
        data: {
          mch_mobile: this.form.phone,
          type: 1,
        },
        callback: ({ code }) => {
          if (code == 200) {
            this.isbinding = false
          }
        },
        errpr: ({ code }) => {
          if (code == 205) {
            this.isbinding = true
          }
        },
      })
      if (this.isphone) {
        if (this.isbinding) {
          this.$root.prompt({
            msg: '手机号已被绑定！',
            customClass: 'mobileMessage_2020_12_9_1_3_9_7',
          })
        }
      } else {
        if (this.isbinding) {
          this.$root.prompt({
            msg: '手机号已被绑定！',
          })
        }
      }
    },
    getPhoneError(phone) {
      this.accountInfo.phoneWarnning = this.checkPhoneOrPassword(phone, 1)
    },
    /**
     * @name: checkPhoneOrPassword
     * @test: test font
     * @msg: 验证手机号或者密码
     * @param {value,type:1手机号 2密码}
     * @return: Boolean true为不匹配
     */
    checkPhoneOrPassword(value, type) {
      let res = false
      if (type == 1) {
        res = !/^1[2-9]\d{9}$/.test(value)
      } else if (type == 2) {
        res = !/^[a-zA-Z0-9]{6,16}$/.test(value)
      } else if (type == 3) {
        res = !/^\d{4}$/.test(value)
      } else {
        res = !/^[a-zA-Z0-9]{4}$/.test(value)
      }
      return res
    },
    /**
     * @name: resetStatus
     * @test: test font
     * @msg: 在切换时改变状态重置状态
     * @param {none}
     * @return: none
     */
    resetStatus() {
      this.accountInfo = {
        code: '',
        phone: '',
        password: '',
        newPassword: '',
        numCode: '',
        phoneWarnning: false,
        passwordWarnning: false,
        numCodeWarnning: false,
        codeWarnning: false,
        newPasswordWarnning: false,
        isLoading: false,
        phoneFlag: false,
      }
      if (
        this.selectedLoginType == 1 &&
        this.isDefaultShowWxlogin &&
        this.isNotReset
      ) {
        this.initWhenHasCookie()
      }
    },
    /**
     * @name: toProtocol
     * @test: test font
     * @msg: 跳转协议页面
     * @param {none}
     * @return: none
     */
    toProtocol(val) {
      const { href } = this.$router.resolve({
        path: '/protocol',
        query: {
          type: val,
        },
      })
      this.protocolHref = href
      this.$store.dispatch('open', this.protocolHref)
    },
    /**
     * 隐私政策 ,跳转蓝湖
     */
    // toPolicy() {
    //   this.$store.dispatch(
    //     'open',
    //     'https://ddkk-team.yuque.com/docs/share/119a977e-a6e4-4276-ab8b-41b736bac727?#  '
    //   )
    // },
    /**
     * @name: changeLoginType
     * @test: test font
     * @msg: 切换登录方式 1.isDefaultShowWxlogin：true  微信登录 2.isDefaultShowWxlogin：false  密码登录
     * @param {none}
     * @return: none
     */
    changeLoginType(val) {
      //微信扫码弹窗使用
      if (val == 1) {
        this.isDefaultShowWxlogin = true

        this.updateUrlHash('account')
      } else {
        if (this.isDefaultShowWxlogin) {
          this.updateUrlHash('wx')
        } else {
          this.updateUrlHash('account')
        }
        this.isDefaultShowWxlogin = !this.isDefaultShowWxlogin
      }
      this.selectedLoginType = 1
      this.hadRegister2 = false
      clearInterval(this.timer)
      // if (!this.isDefaultShowWxlogin) {
      // 如果默认定位到手机账号登录 第一次切换需要获取初始数据
      // if (!this.requestCode) {
      //   this.getCode()
      // } else {
      //   // 开启微信扫码定时器
      //   this.requestCallback()
      // }
      // } else {
      //   // 停止微信扫码定时器
      //   clearInterval(this.timer)
      // }
      this.resetStatus()
    },

    /**
     * @name: toggleArea
     * @test:
     * @msg: 登录区域与注册区域互相切换
     * @param {none}
     * @return: none
     */
    toggleArea(type = 'normal') {
      this.isLoginArea = !this.isLoginArea
      if (type === 'reset') {
        this.isNotReset = false
      } else {
        this.isNotReset = true
        this.hadRegister = true
        this.isDefaultShowWxlogin = true
      }
      if (type == 'us') {
        this.tempnewUser.isNew = this.newUser.isNew
      } else if (type == 'scan') {
        this.isLoginArea = true
        this.isDefaultShowWxlogin = true
        this.hadRegister2 = true
        this.updateUrlHash('register')
        if (!this.requestCode) {
          this.getCode()
        } else {
          // 开启微信扫码定时器
          this.requestCallback()
        }
      }
      this.resetStatus()
    },

    // 切换登录类型
    switchLoginType(type) {
      this.showVerifyCodeComponent_2 = true
      this.showVerifyCodeComponent_3 = true
      if (this.selectedLoginType !== type) {
        this.selectedLoginType = type
      }
      this.resetStatus()
    },

    downLoad() {
      this.$store.dispatch('downLoad', this.downloadUrl)
    },

    getCode(isRequest) {
      const self = this
      self.$http({
        name: 'qrcode',
        url: '/wxCustom/qrcode',
        callback({ data }) {
          self.downloadUrl = data.pcClientDownload
          self.qrcode = data.qrcodeUrl
          // 记住微信扫码code
          self.requestCode = data.code
          if (!isRequest) {
            // 开启2S去后台获取状态
            self.requestCallback()
          }
        },
      })
    },

    requestCallback() {
      // console.log('Math', Math.random)
      const self = this
      clearInterval(self.timer)
      self.timer = setInterval(() => {
        self.$http({
          name: 'requreStatus',
          url: '/wxCustom/requreStatus',
          data: {
            code: self.requestCode,
          },
          callback({ isNew, userInfo, isRegistry, count }) {
            // 停止掉定时器
            clearInterval(self.timer)
            self.newUser.isNew = isNew
            if (self.newUser.isNew) {
              self.toggleArea('us')
            }
            // 判断多个机构 就去选择机构页面
            if (
              (userInfo && userInfo.is_remove == 1 && userInfo.jg_id == 0) ||
              isRegistry
            ) {
              //判断是否被移除网校
              // self.$store.dispatch('getUserInfo').then(() => {
              //   self.$router.replace({
              //     path: '/newschool',
              //   })
              // })
              self.$store.dispatch('getUserInfo').then(() => {
                self.$router.replace({
                  path:
                    count > 1 ? '/switchSchool' : self.$store.getters.homePath,
                })
              })
            } else {
              self.accountInfo.phone = (userInfo && userInfo.umobile) || ''
              self.hadRegister3 = true
              self.hadRegister2 = false
              self.isDefaultShowWxlogin = false
              self.isLoginArea = true
              self.getCode(true)
            }
          },
        })
      }, 2000)
    },
    /**
     * @name: mobileExist
     * @test: test font
     * @msg: 判断手机号是否被注册
     * @param {type:检测类型}
     * @return: none
     */
    mobileExist(event, type = 'resgister', id) {
      this.showVerifyCodeComponent_2 = true
      this.showVerifyCodeComponent_3 = true
      //如果失去焦点,就检查
      // console.log(event)
      const self = this
      if (self.accountInfo.phoneWarnning || self.accountInfo.phone == '') {
        return
      }
      self.warningIs_1 = false
      self.warningIs_2 = false
      self.warningIs_3 = false
      if (id == 1) {
        self.warningIs_1 = true
      }
      if (id == 2) {
        self.warningIs_2 = true
      }
      if (id == 3) {
        self.warningIs_3 = true
      }

      self.$http({
        name: 'mobileExist',
        url: '/WxCustom/mobileExist',
        data: {
          mobile: self.accountInfo.phone,
        },
        callback({ exist }) {
          if ((exist && type === 'resgister') || (!exist && type === 'login')) {
            self.accountInfo.phoneFlag = true

            self.accountInfo.phoneWarnning = true
          } else {
            self.accountInfo.phoneFlag = false

            self.accountInfo.phoneWarnning = false
          }

          self.showVerifyCodeComponent_2 = false

          self.showVerifyCodeComponent_3 = false
        },
      })
    },
    /**
     * @name: mobileExist
     * @test: test font
     * @msg: 判断机构名称是否被注册
     * @param {type:检测类型}
     * @return: none
     */
    jgTitleExist() {
      const self = this
      if (self.accountInfo.titleWarnning || self.accountInfo.title == '') {
        return
      }
      self.$http({
        name: 'titleExit',
        url: '/WxCustom/titleExit',
        data: {
          title: self.accountInfo.title,
        },
        callback({ exist }) {
          if (exist) {
            self.accountInfo.titleFlag = true
            self.accountInfo.titleWarnning = true
          } else {
            self.accountInfo.titleFlag = false
            self.accountInfo.titleWarnning = false
          }
        },
      })
    },
    // 忘记密码
    passForgot() {
      this.warningIs_3 = true
      const self = this
      self.accountInfo.phoneWarnning =
        self.checkPhoneOrPassword(self.accountInfo.phone, 1) ||
        self.accountInfo.phoneFlag
      self.accountInfo.passwordWarnning = self.checkPhoneOrPassword(
        self.accountInfo.password,
        2
      )
      self.accountInfo.numCodeWarnning = self.checkPhoneOrPassword(
        self.accountInfo.numCode,
        3
      )
      if (
        self.accountInfo.phoneWarnning ||
        self.accountInfo.passwordWarnning ||
        self.accountInfo.numCodeWarnning
      ) {
        return
      }
      self.$http({
        name: 'passForgot',
        url: '/WxCustom/passwordForgot',
        data: {
          phone: self.accountInfo.phone,
          verify_code: self.accountInfo.numCode,
          pwd: self.accountInfo.password,
        },
        callback({ code }) {
          if (code == 200) {
            self.$root.prompt({
              msg: '重置成功,请登录',
              type: 'success',
              customClass: self.isphone
                ? 'mobileMessage_2020_12_9_1_3_9_7'
                : '',
            })
            self.isLoginArea = true
            self.isDefaultShowWxlogin = true
            self.selectedLoginType = 1
          }
        },
        error(data) {
          if (data.code == 205 && data.info == '验证码错误') {
            self.accountInfo.numCodeWarnning = true
          }
        },
      })
    },
    // 验证码登录
    verifyLogin() {
      this.warningIs_2 = true
      //电脑手机用一个登录按钮,验证码登录
      const self = this
      self.accountInfo.phoneWarnning =
        self.checkPhoneOrPassword(self.accountInfo.phone, 1) ||
        self.accountInfo.phoneFlag
      self.accountInfo.numCodeWarnning = self.checkPhoneOrPassword(
        self.accountInfo.numCode,
        3
      )
      if (self.accountInfo.phoneWarnning || self.accountInfo.numCodeWarnning) {
        return
      }
      self.$http({
        name: 'verifyLogin',
        url: '/WxCustom/verifyLogin',
        data: {
          phone: self.accountInfo.phone,
          verify_code: self.accountInfo.numCode,
        },
        callback({ code, count }) {
          if (code == 200) {
            self.$root.prompt({
              msg: '登录成功',
              type: 'success',
              customClass: self.isphone
                ? 'mobileMessage_2020_12_9_1_3_9_7'
                : '',
            })
            self.$store.dispatch('getUserInfo').then(() => {
              // if (self.$store.state.userInfo.newschool == 1) {
              //   self.$router.replace({
              //     path: '/newschool',
              //   })
              // } else {
              self.$router.replace({
                path:
                  count > 1 ? '/switchSchool' : self.$store.getters.homePath,
              })
              // }
            })
          }
        },
        error(data) {
          if (data.code == 205 && data.info == '验证码错误') {
            self.accountInfo.numCodeWarnning = true
          }
        },
      })
    },
    // 绑定用户
    bindUser() {
      const self = this
      self.accountInfo.titleWarnning =
        self.newUser.isNew && !self.accountInfo.title
      self.accountInfo.phoneWarnning =
        self.checkPhoneOrPassword(self.accountInfo.phone, 1) ||
        self.accountInfo.phoneFlag
      self.accountInfo.passwordWarnning = self.checkPhoneOrPassword(
        self.accountInfo.password,
        2
      )
      self.accountInfo.numCodeWarnning = self.checkPhoneOrPassword(
        self.accountInfo.numCode,
        3
      )
      if (
        self.accountInfo.titleWarnning ||
        self.accountInfo.phoneWarnning ||
        self.accountInfo.passwordWarnning ||
        self.accountInfo.numCodeWarnning
      ) {
        return
      }
      if (!self.isAgreement) {
        self.$root.prompt('需同意协议')
        return
      }
      const data = {
        mobile: self.accountInfo.phone,
        code: self.accountInfo.numCode,
        pwd: self.accountInfo.password,
        type: self.newUser.isNew ? 2 : 1,
      }
      if (self.newUser.isNew) {
        data.title = self.accountInfo.title
      }
      self.$http({
        name: 'bindUser',
        url: '/User/bindUser',
        data,
        callback({ code, count }) {
          if (code == 200) {
            // 手机号登录就直接进后台首页
            self.isShowToast = true
            setTimeout(() => {
              self.isShowToast = false
            }, 4000)
            self.$store.dispatch('getUserInfo').then(() => {
              self.$router.replace({
                path:
                  count > 1 ? '/switchSchool' : self.$store.getters.homePath,
              })
            })
          }
        },
        error(data) {
          if (data.code == 205 && data.info == '验证码有误') {
            self.accountInfo.numCodeWarnning = true
          }
        },
      })
    },
    // 开始账号登录
    accountLogin() {
      this.warningIs_1 = true
      //密码登录
      const self = this
      self.accountInfo.phoneWarnning =
        self.checkPhoneOrPassword(self.accountInfo.phone, 1) ||
        self.accountInfo.phoneFlag
      self.accountInfo.passwordWarnning = self.checkPhoneOrPassword(
        self.accountInfo.password,
        2
      )
      if (self.accountInfo.phoneWarnning || self.accountInfo.passwordWarnning) {
        return
      }
      let appID = ''
      if (getEnv() == 'localhost' || getEnv() == 'test') {
        appID = '2059023652'
      } else {
        appID = '2043905377'
      }
      var captchapassword = new window.TencentCaptcha(appID, function(res) {
        if (res.ret == 0) {
          self.accountInfo.isLoading = true
          self.$http({
            name: 'accountLogin',
            url: '/wxCustom/AccountLogin',
            data: {
              phone: self.accountInfo.phone,
              is_persistence: self.is_persistence,
              password: self.accountInfo.password,
              Randstr: res.randstr,
              Ticket: res.ticket,
            },
            callback({ count }) {
              if (self.is_persistence) {
                setCookie('is_persistence', 'yes')
              } else {
                setCookie('is_persistence', '')
              }
              // 手机号登录就直接进后台首页
              if (self.isRememberPassword) {
                setCookie('lusername', self.accountInfo.phone)
                setCookie('lpassword', self.accountInfo.password)
              } else {
                setCookie('lusername', '')
                setCookie('lpassword', '')
              }

              self.$root.prompt({
                msg: '登录成功',
                type: 'success',
                customClass: self.isphone
                  ? 'mobileMessage_2020_12_9_1_3_9_7'
                  : '',
              })

              self.$store.dispatch('getUserInfo').then(() => {
                // if (self.$store.state.userInfo.newschool == 1) {
                //   self.$router.replace({
                //     path: '/newschool',
                //   })
                // } else {
                self.$router.replace({
                  path:
                    count > 1 ? '/switchSchool' : self.$store.getters.homePath,
                })
                // }
              })
            },
            error() {
              self.accountInfo.isLoading = false
            },
          })
        }
      })
      try {
        captchapassword.show()
      } catch (e) {
        this.$root.prompt('加载验证码失败，请检查网络或刷新重试')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
}
.policy {
  float: left;
  margin-left: 6px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.newpwdClass {
  .el-input {
    width: 297px;
  }
}

.newpwdClassPhone {
  .el-input {
    width: 333px;
  }
}

.divdiv {
  position: absolute;
  width: 82px;
  height: 36px;
  // background: red;
  top: 0;
}

.divdivMobile {
  width: 100px;
  height: 46px;
}

.disabled {
  color: #c0c4cc !important;
  cursor: not-allowed !important;
  opacity: 0.6 !important;
}

.AllPageBox {
  width: 375px;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent !important; //去除点击高亮
}

.loginSelected:after {
  width: 38px !important;
  height: 3px !important;
  bottom: -8px !important;
}

.mobleCode {
  width: 100px !important;
  height: 46px !important;
  font-size: 14px !important;
  border: 1px solid #0aa29b !important;
  border-radius: 6px !important;
}

.mobileplaceholder {
  input::-webkit-input-placeholder {
    font-size: 13px !important;
  }
}

.mobileInput {
  height: 46px !important;
  font-size: 13px !important;
  border-radius: 6px !important;
}

.loginMobile {
  font-size: 16px !important;
  line-height: 21px !important;
}

.headerImgMobile {
  width: 140px;
}

.boxMobile {
  margin-top: 20px !important;
  width: 375px;

  .menu {
    padding-left: 0 !important;
  }

  .zcCodeBtn {
    background: #fff !important;
    color: #0aa29b !important;
  }

  // .jtGoOther i {
  //   width: 25px !important;
  //   height: 26px !important;
  // }

  ::v-deep .el-input__inner {
    height: 46px !important;
    font-size: 13px !important;
    border-radius: 6px !important;
  }

  ::v-deep .el-input__inner:focus {
    outline: none;
    border: 1px solid;
    border-color: #0aa29b;
  }

  ::v-deep input[data-v-26084dc2]:focus {
    border: 1px solid;
    border-color: #0aa29b;
  }

  input {
    height: 46px !important;
    font-size: 13px !important;
    border-radius: 6px !important;
  }

  ::v-deep input::-webkit-input-placeholder {
    font-size: 13px !important;
  }

  ::v-deep .account-input i {
    bottom: -18px !important;
    font-size: 11px !important;
  }

  ::v-deep .RadioChecked_radioStyle7_8oAfb {
    width: 0.7rem;
    height: 0.7rem;
  }

  ::v-deep .RadioChecked_radioStyle7_8oAfb:after {
    left: 0.2rem;
    top: 0.1rem;
    width: 0.2rem;
    height: 0.3rem;
  }

  // ::v-deep .el-input__suffix {
  //   width: 100px;
  // }

  // ::v-deep .el-input__suffix-inner {
  //   width: 50px;
  // }

  // ::v-deep .el-input__clear {
  //   width: 50px;
  //   font-size: 36px;
  // }

  .rePassword {
    width: 100px !important;
    height: 46px !important;
    font-size: 13px !important;
    border: 1px solid #0aa29b !important;
  }
}

.formInPhone {
  margin-top: 20px !important;

  ::v-deep .el-form-item__label {
    line-height: 16px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #333333;
  }
}

::v-deep .el-form-item {
  margin-bottom: 20px;
}

@keyframes trans {
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.small_zc {
  width: 100% !important;
}

.small_zc_bt {
  width: 100% !important;
  margin: 14px auto !important;
  height: 46px !important;
  font-size: 14px !important;
  display: block !important;
  border-radius: 6px !important;
}

.small_zc_bt2 {
  justify-content: flex-end !important;
  width: 100% !important;
  margin-left: 0 !important;
  font-size: 12px !important;
}

.small {
  padding: 20px !important;
  margin: 0 auto !important;
  width: 375px !important;
  height: 100% !important;
  box-sizing: border-box !important;
  display: flex;
  justify-content: center;
}

.small2 {
  margin-left: 2px !important;
}

.main {
  width: 980px;
  height: 500px;
  position: relative;
  font-size: 0;
  background: #fff;
  margin: 40px auto 0;
  &.windowAppV2 {
    width: 100%;
    height: auto;
    min-height: 568px;
    max-width: 860px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .left {
      transform: scale(0.8775);
      padding: 68px 0 0 81px;
    }
    .right {
      transform: scale(0.8775);
      margin-left: 32px;
    }
  }
  .left {
    text-align: center;
    padding: 48px 0 0 81px;

    .t1 {
      font-size: 24px;
      line-height: 31px;
      color: rgba(51, 51, 51, 1);
    }

    .t2 {
      font-size: 15px;
      margin-top: 16px;
      line-height: 20px;
      color: rgba(102, 102, 102, 1);
    }

    .t3 {
      width: 333px;
      height: auto;
      margin-top: 37px;
    }
  }

  .right {
    margin-top: 63px;

    min-width: 289px;
    margin-left: 102px;

    .menu {
      display: flex;
      align-items: center;
      .t1 {
        flex: 1;
        color: #333;
        cursor: pointer;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        position: relative;

        &.selected {
          &:after {
            content: '';
            width: 38px;
            height: 3px;
            position: absolute;
            left: 50%;
            bottom: -13px;
            background: #1b9d97;
            transform: translateX(-50%);
          }
        }
      }
    }

    .t2 {
      width: 178px;
      height: 178px;
      padding: 10px;
      border-radius: 4px;
      margin: 36px auto 0;
      box-sizing: border-box;
      border: 1px solid #e7e7e7;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .default-t2 {
      width: 200px;
      height: 200px;

      &.register-code {
        opacity: 0;
        transform: translateX(60px);
        animation: 0.5s trans ease 0.2s;
        animation-fill-mode: forwards;
      }
    }

    .t3 {
      text-align: center;
      font-size: 13px;
      margin-top: 18px;
      line-height: 17px;
      color: rgba(102, 102, 102, 1);
    }

    .t4 {
      font-size: 13px;
      margin-top: 21px;
      line-height: 17px;
      color: rgba(204, 204, 204, 1);
    }

    .t5 {
      cursor: pointer;
      font-size: 13px;
      margin-top: 18px;
      line-height: 17px;
      color: rgba(10, 162, 155, 1);
    }

    .no-phone {
      text-align: center;

      .warning-icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        margin: 80px 0 34px;
        background: url(../assets/img/login/ico_gth@2x.png) no-repeat
          center/100% 100%;
      }

      .warning-txt {
        margin-bottom: 34px;
        font-size: 14px;
        color: rgba(255, 53, 53, 1);
        line-height: 19px;
      }
    }
  }

  .change-login-type {
    position: absolute;
    right: 22px;
    top: 22px;
    width: 44px;
    height: 44px;
    background: url(../assets/img/login/ico_mmdl@2x.png) no-repeat center/100%
      100%;
    cursor: pointer;

    &::before {
      display: inline-block;
      content: '密码登录';
      position: absolute;
      left: -73px;
      top: 0;
      height: 26px;
      padding: 0 15px 0 9px;
      background: url(../assets/img/login/ico_wxdlbg@2x.png) no-repeat
        center/100% 100%;
      line-height: 26px;
      font-size: 12px;
      text-align: center;
      color: #0aa29b;
    }
  }

  .password-login-type {
    background: url(../assets/img/login/ico_smdl@2x.png) no-repeat;
    background-size: 100% 100%;

    &::before {
      content: '微信登录';
    }
  }
}

.footer {
  font-size: 12px;
  margin-top: 36px;
  line-height: 20px;
  text-align: center;
  color: rgba(155, 155, 155, 1);
}

.tishi-box {
  position: fixed;
  width: 480px;
  left: 50%;
  top: 50%;
  padding: 20px 30px;
  transform: translate(-50%, -50%);
  background: #fff;
  box-sizing: border-box;

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
  }

  p {
    padding: 40px 0 87px;
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: rgba(27, 157, 151, 1);
      line-height: 19px;
      cursor: pointer;
    }

    button {
      width: 68px;
    }
  }
}

/**密码登录**/
.account-input {
  // margin-bottom: 20px;
  &.mt {
    // margin-top: 36px;
  }

  input {
    color: #333;
    width: 100%;
    height: 36px;
    display: block;
    font-size: 14px;
    padding-left: 10px;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #dddddd;

    &:focus {
      border-color: #0aa29b;
    }

    &::-webkit-input-placeholder {
      color: #9b9b9b;
      font-size: 12px;
    }
  }

  i {
    position: absolute;
    left: 0;
    bottom: -17px;
    font-size: 12px;
    color: rgba(255, 53, 53, 1);
    line-height: 16px;
    opacity: 0;
  }
}

.warnning-input {
  position: relative;

  ::v-deep input {
    border: 1px solid #ff3535;

    &:focus {
      border-color: #ff3535;
    }
  }

  i {
    opacity: 1;
  }
}

/**验证码***/
.verify-code {
  display: flex;
  align-items: center;

  input {
    flex: 1;
  }

  .code {
    width: 82px;
    height: 36px;
    border-radius: 1px;
    margin-left: 13px;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .register-code2 {
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    line-height: 36px;
    border: 1px solid rgba(10, 162, 155, 1);
    background: rgba(10, 162, 155, 1);
  }

  .register-code {
    font-size: 12px;
    color: rgba(10, 162, 155, 1);
    line-height: 36px;
    cursor: pointer;
    border: 1px solid rgba(10, 162, 155, 1);
    background: none;
  }
}

.agreement-txt {
  margin-left: 82px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  text-align: left;
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  cursor: pointer;

  span {
    text-decoration: underline;
  }
}

.go-other {
  display: inline-block;
  margin-top: 32px;
  font-size: 13px;
  color: rgba(10, 162, 155, 1);
  cursor: pointer;

  * {
    vertical-align: middle;
  }

  i {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 12px;
    height: 11px;
    margin-left: 11px;
    background: url(../assets/img/login/ico_dljt@2x.png) no-repeat center/100%
      100%;
  }
}

.remember-pas-box {
  width: 78%;
  display: flex;
  margin-left: 82px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  .left-span-contain {
    display: flex;
    align-items: center;
    .left-span {
      display: flex;
      cursor: pointer;
      user-select: none;
      justify-content: space-between;
      align-items: center;
      margin-right: 20px;
    }
  }

  div {
    cursor: pointer;
  }
}

.account-login-btn {
  margin-left: 82px;
  cursor: pointer;
  border: 0;
  color: #fff;
  width: 78%;
  height: 36px;
  display: block;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  background: #0aa29b;
  border-radius: 2px;
}

.account-login-btn-register {
  cursor: pointer;
  border: 0;
  color: #0aa29b;
  width: 100%;
  height: 38px;
  display: block;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  border: 1px solid #0aa29b;
  background: #fff;
  border-radius: 2px;
}

.register-btn {
  border-radius: 2px;
  border: 1px solid rgba(27, 157, 151, 1);
  background: none;
  color: rgba(27, 157, 151, 1);
  margin-top: 10px;
  transition: all 0.3s;

  // &:hover {
  //   color: #fff;
  //   border-color: rgba(27, 157, 151, 1);
  //   background: rgba(27, 157, 151, 1);
  // }
}

.toast-txt {
  position: fixed;
  top: 20px;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background: #f39700;
  z-index: 81;
}

.nav {
  display: flex;
  align-items: center;
  text-align: center;
}

.nav .role-url {
  font-size: 14px;
  color: #0aa29b;
  flex: 1;
  padding: 20px 0;
  text-decoration: underline;
  cursor: pointer;
}
</style>
